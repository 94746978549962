@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Mohave);




$lato: 'Lato', sans-serif;

$dull-black: #4A4A4A !default;
$black-base: #000000 !default;
$white-base: #ffffff !default;


$peter-river: #3498db;
$turquise: #1abc9c;
$green-sea: #16a085;
$wet-asphalt: #34495e;
$concrete: #95a5a6;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$midnight-blue: #2c3e50;

/** Animation stuffs */
$default-animation-time: 0.2s !default;
$default-transition-time: 0.2s !default;
$default-shake-distance: 8px !default;
$default-short-slide-distance: 40px !default;
$default-cubic-in: cubic-bezier(0.5, 1.8, 0.9, 0.8);

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: 'Lato', sans-serif;
  color: #333;
}

.card {
  background-color: red;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.app_main_wrapper {
	position: relative;
	width:100%;
	height:100%;
}

.global_pop_wrapper {
	position:fixed;
	z-index:99;
	top:0;
	left:0;
	margin:0;
	padding:0;
	width:100%;
	height:100%;
	background: #fff;
}

a {
	color: #31579d;
	text-decoration: none;
}

.main_header_link a {
	display: inline-block;
	width: 170px;
	cursor: pointer;
}

.menu_link {
	display: inline-block;
	width: 170px;
	cursor: pointer;
}

.activeMenuLink {
	color:#ee0064;
	display: inline-block;
	width: 170px;
	cursor: pointer;
}

.nonactiveHeaderLink {
    color: #fff;
}

.activeHeaderLink {
    color: #a9d800;
}

.country_select select{
	width: 300px;
}

input[type="checkbox"] {
  width:10px;
  height:10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.avatar_menu_img {
	border-radius: 50%;
	border:1px solid #ccc;
}
.avatar_menu_name {
 white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
   vertical-align: middle;
     justify-content: center;
	 line-height: 40px;
}

button:focus {outline:0;}


.bg_black_transp {
	position:fixed;
	z-index:6;
	margin:0;
	padding:0;
	width:100%;
	height:60px;
	background: rgba(0,0,0,0.6);
}

.logo_top_left {
	position:fixed;
	z-index:3;
	top:10px;
	left:20px;
}

.logo_top_left img{
	width: 170px;
}

.sign_transp_wrapper {
	position:fixed;
	z-index:10;
	top:80px;
	right:50%;
	margin-right:-725px;
       width: 450px;
	height:510px;
       overflow: hidden;
	   border-radius: 4px;
	background: rgba(0,0,0,0.8);
}

.signup_transp_wrapper {
	position:fixed;
	z-index:10;
	top:80px;
	right:50%;
	margin-right:-725px;
       width: 450px;
	height:550px;
       overflow: hidden;
	   border-radius: 4px;
	background: rgba(0,0,0,0.8);
}

.resetpswd_transp_wrapper {
	position:fixed;
	z-index:10;
	top:80px;
	right:50%;
	margin-right:-725px;
       width: 450px;
	height:440px;
       overflow: hidden;
	   border-radius: 4px;
	background: rgba(0,0,0,0.8);
}

.sign_transp_inner {
	padding: 40px;
	padding-top: 30px;
}

.sign_title {
	font-size:32px;
	color:#fff;
	margin-bottom:20px;
	text-align: center;
	width:370px;
}

/* Text Input */
.basic_ti{
	height:40px;
	padding:6px;
	background:#fff;width:358px;
	border:1px solid #111;
	border-radius:4px;
}
.basic_ti_top{
	height:40px;
	padding:6px;
	background:#fff;width:358px;
	border:1px solid #111;
	border-radius:4px;
	border-bottom:none;
	-moz-border-radius-bottomleft:0px;
		-webkit-border-bottom-left-radius:0px;
		border-bottom-left-radius:0px;
		-moz-border-radius-bottomright:0px;
		-webkit-border-bottom-right-radius:0px;
		border-bottom-right-radius:0px;
}
.basic_ti_middle{
	height:40px;
	padding:6px;
	background:#fff;width:358px;
	border:1px solid #111;border-top:1px solid #bbb;
	border-bottom:none;
	border-radius:none;
	-moz-border-radius-topleft:0px;
			-webkit-border-radius:0px;
			border-radius:0px;
			-moz-border-radius:0px;
			-webkit-border-radius:0px;
			border-radius:0px;
}
.basic_ti_bottom{
	height:40px;
	padding:6px;
	background:#fff;width:358px;
      border-radius:4px;
	border:1px solid #111;border-top:1px solid #bbb;
	-moz-border-radius-topleft:0px;
			-webkit-border-top-left-radius:0px;
			border-top-left-radius:0px;
			-moz-border-radius-topright:0px;
			-webkit-border-top-right-radius:0px;
			border-top-right-radius:0px;
}


.mobileMenuOverlay {
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}


/* BUTTON */
.buttonBlackText {
	color: #000;
      font-size: 16px;
     font-weight: 400;
     text-align: center;
     width: 200px ;
	 height: 40px;
     justify-content: center;
    line-height: 40px;
   border-radius: 4px;
   cursor:pointer;
}

.buttonWhiteText {
	color: #fff;
      font-size: 16px;
     font-weight: 400;
     text-align: center;
     width: 200px ;
	 height: 40px;
     justify-content: center;
    line-height: 40px;
   border-radius: 4px;
   cursor:pointer;
}

/* HEADER */
.main_header {
	position:fixed;
	z-index:9;
	top:0;
	left: 0;
   width: 100%;
   height: 70px;
   background: rgba(255,255,255,0.95);
  border-bottom: 1px solid #eee;
}

.main_header_connected{
	position:fixed;
	z-index:9;
	top:0;
	left: 0;
   width: 100%;
   height: 60px;
   background: rgba(255,255,255,0.8);
  overflow: hidden;
}


.main_header_inside {
	width: 98%;
       height: 50px;
	margin: auto;
	margin-top:5px;
      overflow: hidden;
}

.main_header_inside_mobile {
	width: 98%;
       height: 50px;
	margin: auto;
	margin-top:5px;
      overflow: hidden;
}




.main_header_inside_left {
       width: 170px;
       height: 50px;
	    justify-content: center;
	   line-height: 50px;
	   float: left;
}

.main_header_inside_left_2 {
       height: 50px;
	    justify-content: center;
	   line-height: 50px;
	   float: left;
	   margin-left: 30px;
}

.main_header_inside_left_2 a{
	display: inline-block;
	width: 100px;
	text-align: center;
	font-size: 16px;
}


.main_header_inside_left img{
	width: 170px;
	 vertical-align: middle;
}

.main_header_inside_left_mobile {
	width: 30%;
       height: 50px;
	    justify-content: center;
	   line-height: 50px;
	   float: left;
}
.main_header_inside_left_mobile img{
	width: 100%;
	 vertical-align: middle;
}



.main_header_inside_center {
	margin-left: 20px;
	width: 480px;
       height: 44px;
	   margin-top:3px;
    justify-content: center;
	 float: left;
}


//.mainmenu li a span { display: none; } /* Hide text */
.mainmenu {
  width:100%;
  margin: 0;
  padding: 0;
  list-style-type: none; /* remove default list style */
}
.mainmenu li {
  margin: 0;
  padding: 0;
    width:80px;
  text-align:center;
  height: 50px;
  float: left;
}
.mainmenu li a {
  float: left;
    width:80px;
    text-align:center;
    height: 50px;
  padding: 0;
  margin: 0;
}


.navLinkactive {
	color: #5d901e;
}

.navLinkLabel {
	color: #777;
}

.navLinkactive .navLinkLabel {
	color: #5d901e;
}



.liHome .navLinkinactive .navLinkIcon {
	  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/feedgrey.png) no-repeat;
	  width: 32px;
	  height: 32px;
	  display: inline-block;
	    background-size: 32px 32px;
}
.liHome .navLinkactive .navLinkIcon {
       background: url(https://s3.amazonaws.com/debyooinc19.img/nav/feedgreen.png) no-repeat;
       width: 32px;
      height: 32px;
      display: inline-block;
	background-size: 32px 32px;
}


.liSearch .navLinkinactive .navLinkIcon {
	  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/searchgrey.png) no-repeat;
	  width: 32px;
	  height: 32px;
	  display: inline-block;
	    background-size: 32px 32px;
}
.liSearch .navLinkactive .navLinkIcon {
       background: url(https://s3.amazonaws.com/debyooinc19.img/nav/searchgreen.png) no-repeat;
       width: 32px;
      height: 32px;
      display: inline-block;
	background-size: 32px 32px;
}


.liDChat .navLinkinactive .navLinkIcon {
	  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dchatgrey.png) no-repeat;
	  width: 32px;
	  height: 32px;
	  display: inline-block;
	    background-size: 32px 32px;
}
.liDChat .navLinkactive .navLinkIcon {
       background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dchatgreen.png) no-repeat;
       width: 32px;
      height: 32px;
      display: inline-block;
	background-size: 32px 32px;
}


.liNewStory .navLinkinactive .navLinkIcon {
	  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/newstorygrey.png) no-repeat;
	  width: 32px;
	  height: 32px;
	  display: inline-block;
	    background-size: 32px 32px;
}
.liNewStory .navLinkactive .navLinkIcon {
       background: url(https://s3.amazonaws.com/debyooinc19.img/nav/newstorygreen.png) no-repeat;
       width: 32px;
      height: 32px;
      display: inline-block;
	background-size: 32px 32px;
}


.liDShop .navLinkinactive .navLinkIcon {
	  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dshopgrey.png) no-repeat;
	  width: 32px;
	  height: 32px;
	  display: inline-block;
	    background-size: 32px 32px;
}
.liDShop .navLinkactive .navLinkIcon {
       background: url(https://s3.amazonaws.com/debyooinc19.img/nav/dshopgreen.png) no-repeat;
       width: 32px;
      height: 32px;
      display: inline-block;
	background-size: 32px 32px;
}



.liDPlay .navLinkinactive .navLinkIcon {
	  background: url(https://s3.amazonaws.com/debyooinc19.img/nav/librarygrey.png) no-repeat;
	  width: 32px;
	  height: 32px;
	  display: inline-block;
	    background-size: 32px 32px;
}
.liDPlay .navLinkactive .navLinkIcon {
       background: url(https://s3.amazonaws.com/debyooinc19.img/nav/librarygreen.png) no-repeat;
       width: 32px;
      height: 32px;
      display: inline-block;
	background-size: 32px 32px;
}





.main_header_inside_center_mobile {
	text-align:  center;
	width: 40%;
       height: 50px;
	   line-height: 50px;
	   float: left;
}
.main_header_inside_center_mobile img{
	width: 100%;
	 vertical-align: middle;
}


.main_header_inside_right {
	position: relative;
	text-align:  right;
	width: 350px;
       height: 50px;
	   float: right;
}
.main_header_inside_right img{
	height: 50px;
	 vertical-align: middle;
}


.main_header_inside_right_1 {
	position: relative;
	   float: right;
	   margin-left:20px;
	     margin-top:5px;
}

.main_header_inside_right_2 {
	position: relative;
	   float: right;
	   margin-top:5px;
}


.main_header_inside_right_mobile {
	position: relative;
	text-align:  right;
	width: 30%;
       height: 50px;
	     line-height: 50px;
	   float: right;
}
.main_header_inside_right_mobile img{
	width: 100%;
	 vertical-align: middle;
}



.menu_container {
  position: fixed;
  top: 19px;
  right: 7px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 9999;
  transition: opacity .25s ease;

  &:hover {
    opacity: 1;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: #777;
    }
    .middle {
      opacity: 0;
      background: #777;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: #777;
    }
  }

  span {
  background: #fff;
  border: none;
  height: 3px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition:  all .35s ease;
  cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}



.overlay {
  position: fixed;
   z-index: 10;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;

      &:nth-of-type(2) {
        animation-delay: .4s;
      }
      &:nth-of-type(3) {
        animation-delay: .45s;
      }
      &:nth-of-type(4) {
        animation-delay: .50s;
      }
    }
  }
  nav {
    position: relative;
    height: 70%;
    top: 35%;
    transform: translateY(-50%);
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    text-align: center;
    width: 98% ;
	margin: auto;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
     width: 100% ;

    li {
      display: block;
      height: 50px;
      min-height: 50px;
	  line-height: 50px;
	  width: 100% ;
      position: relative;
      opacity: 0;

      a {
        display: block;
        position: relative;
        color: #333;
        text-decoration: none;
        overflow: hidden;
        vertical-align: middle;

        &:hover:after,
         &:focus:after,
         &:active:after {
           width: 100%;
         }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: #FFF;
          transition: .35s;
        }
      }
    }
  }
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}


.logo-overlay-menu-mobile {
	margin: 17px;
	margin-left: 10px;
}
.logo-overlay-menu-mobile img {
	width: 140px;
}

/* END OF HEADER */


/* BODY */


.white-round-container  {
	background: #fff;
	width:1020px;
	border-radius: 6px;
	border: 0.5pt solid #ddd;
}
.padding-20  {
	padding: 20px;
}

.global-activeuser-changed-icon{
	width:60px;
	height:60px;
	line-height:65px;
	text-align:center;
	background:rgb(141, 200, 44);
	border-radius:50%;
	font-size:45px;
	color:#FFF;
	margin:auto;
	margin-top:30px;
}

.page-title {
	color: #222;
	font-size: 35px;
	font-weight: bold;
	line-height: 40px;
}

.clear-both {
	clear: both;
}

.h40 {
	height: 40px;
}

.float-25pc {
	float:left;
	width: 25%;
}

.float-50pc {
	float:left;
	width: 50%;
}

.page-subtitle-underlined {
	color: #222;
	font-size: 18px;
	line-height: 30px;
	width: 96%;
	border-bottom: 1px solid #ccc;
}

.default-text {
	color: #333;
	font-size: 14px;
}


/* Home */
.home-title-container  {
	position:absolute;
	z-index:6;
	top:80px;
	left:50%;
	width:1020px;
	margin-left:-510px;
	text-align:center;
}
.home-title-container  .title{
	position:relative;
	color: #111;
	font-size: 50px;
	font-weight: bold;
	line-height: 60px;
	text-align:center;
}


.home-title-container-mobile  {
	position:absolute;
	z-index:6;
	top:75px;
	left:2%;
	width:96%;
	text-align:center;
}
.home-title-container-mobile  .title{
	position:relative;
	color: #111;
	font-size: 23px;
	font-weight: bold;
	line-height: 26px;
	text-align:center;
}


.home-text-container  {
	position:absolute;
	z-index:6;
	top:27%;
	right: 50%;
   width: 570px;
   height: auto;
   margin-right:-540px;

}

.home-text-container  .title{
	position:relative;
	color: #111;
	font-size: 80px;
	font-weight: bold;
	line-height: 90px;
}

.home-text-container  .sub-title-big{
	margin-top:10px;
	position:relative;
	color: #111;
	font-size: 55px;
	line-height: 60px;
}

.home-text-container  .sub-title{
	margin-top:10px;
	position:relative;
	color: #111;
	font-size: 45px;
	line-height: 55px;
}

.home-text-container  .body-text{
        position:relative;
		margin-top: 20px;
	color: #222;
	font-size: 40px;
	line-height: 50px;
}

/* Contact */
.contact-container  {
	position:absolute;
	z-index:6;
	top:80px;
	left:50%;
	width:1020px;
	margin-left:-510px;
}


/* END OF BODY */



/* FOOTER */
.home_footer_right  {
  position:absolute;
  z-index:9;
   bottom:10px;
  right: 50%;
  margin-right: -725px;
   height: 30px;
   line-height: 30px;
   border-radius: 15px;
   padding-left:15px;
   padding-right:15px;
   background: rgba(255,255,255,0.1);
   text-align:center;
  overflow: hidden;
}
.home_footer_right a{
	color: #333;
}


/* END OF FOOTER */




.slide-container {
  width: 100vw;
  height: 100vh;
  margin: auto; }

h3 {
  text-align: center;
}


.each-slide2 > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}


.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
  width: 100%;
  height: 100vh;
}

.each-fade .image-container {
	position:absolute;
	z-index:8;
	top:26%;
	left: 50%;
   width: 450px;
   margin-left:-540px;
  overflow: hidden;
}

.each-fade .image-container img {
 width: 450px;
}


.each-fade .signin-image-container {
	position:absolute;
	z-index:8;
	top:26%;
	left: 50%;
	margin-left:-725px;
   width: 450px;
  overflow: hidden;
}

.each-fade .signin-image-container img {
 width: 450px;
}





.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.bg_img_wrapper {
position:absolute;
z-index:8;
top:0;
bottom:0;
width:300px;
height:600px;
margin-left:0px;
}
.bg_img_wrapper img {
  width:300px;
  height:600px;
}


.bg_elegance {
background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%);
 background-blend-mode: screen;
}

.bg_chemic_aqua {
	background-color: #CDDCDC;
	 background-image: radial-gradient(at 50% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(0,0,0,0.25) 100%);
	 background-blend-mode: screen, overlay;
}

.bg_glass_water  {
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
}

.bg_earl_gray {
	background-color: #E4E4E1;
	 background-image: radial-gradient(at top center, rgba(255,255,255,0.03) 0%, rgba(0,0,0,0.03) 100%), linear-gradient(to top, rgba(255,255,255,0.1) 0%, rgba(143,152,157,0.60) 100%);
	 background-blend-mode: normal, multiply;
}

.bg_mole_hall {
background-image: linear-gradient(-20deg, #616161 0%, #9bc5c3 100%);
}

.bg_kind_steel {
 background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.bg_clean_mirror  {
    background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
}
.bg_jungle_day  {
background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%);
}



/* CHECKBOX */
$color__back: #fff;
$color__back--high: lighten($color__back, 20%);
$color__text: #fff;
$color__text--low: darken($color__text, 50%);
$color__primary: #0867ae;

$checkbox__size: 40px;
$switch__size: $checkbox__size;

$checkbox__border__size: 2px;
$checkbox__border__color: $color__primary;
$checkbox__border__color--disabled: $color__back--high;

$checkbox__checkmark__size: $checkbox__size - (2*$checkbox__border__size);
$checkbox__checkmark__size--indeterminate: 2px;
$checkbox__checkmark__color: $color__primary;
$checkbox__checkmark__color--disabled: $color__back--high;

$checkbox__label__color: $color__text;
$checkbox__label__color--disabled: $color__text--low;




.m-checkbox {
  display: flex;
  align-items: center;
  padding: .5rem;

  &__label {
    flex-shrink: 0;
    padding: .5rem 1rem;
    color: $checkbox__label__color;
    cursor: pointer;
  }

  &__input {
    position: relative;
    flex-shrink: 0;
    width: $checkbox__size;
    height: $checkbox__size;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background: $color__back;
    cursor: pointer;
    border: $checkbox__border__size solid $checkbox__border__color;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all .1s;
      background: $checkbox__checkmark__color;
    }

    &:checked,
    &:indeterminate {
      &::before {
        top: $checkbox__border__size;
        right: $checkbox__border__size;
        bottom: $checkbox__border__size;
        left: $checkbox__border__size;
      }
    }

    &:indeterminate {
      &::before {
        top: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
        bottom: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
      }
    }

    &:disabled {
      border-color: $checkbox__border__color--disabled;
      cursor: default;

      &::before {
        background-color: $checkbox__checkmark__color--disabled;
      }

      + .m-checkbox__label {
        color: $checkbox__label__color--disabled;
        cursor: default;
      }
    }
  }

  &--has-error {
    &__input {
      border-color: red;
      background-color: rgba(red, .2);

      &::before {
        background-color: red;
      }
    }
  }

  &--switch {
    &__label {
      &::after {
        content: 'off';
        margin-left: .25rem;
      }
    }

    &__input {
      width: (2 * $switch__size) - (2 * $checkbox__border__size);
      height: $switch__size;
      border: $checkbox__border__size solid $checkbox__border__color--disabled;
      border-radius: $checkbox__checkmark__size;

      &::before {
        top: $checkbox__border__size;
        right: $switch__size - $checkbox__border__size;
        bottom: $checkbox__border__size;
        left: $checkbox__border__size;
        border-radius: 50%;
        background: $checkbox__checkmark__color--disabled;
      }

      &:checked {
        border-color: $checkbox__border__color;

        + .m-checkbox--switch__label {
          &::after {
            content: 'on';
          }
        }

        &::before {
          right: $checkbox__border__size;
          left: $switch__size - $checkbox__border__size;
          background: $checkbox__border__color;
        }
      }
    }
  }
}




/* ========================================================= */
/* Dropdown menu Source */
/* ========================================================= */
$dd-menu-sm-width: 150px !default;
$dd-menu-md-width: 300px !default;
$dd-menu-lg-width: 450px !default;
$dd-menu-z-index: 7;
$dd-font: $lato;
$dd-text-color: $black-base;
$dd-list-color: $white-base;
$dd-hover-color: $silver;
$dd-hover-text-color: $black-base;

$dd-inverse-text-color: $white-base;
$dd-inverse-list-color: $midnight-blue;
$dd-inverse-hover-color: $wet-asphalt;
$dd-inverse-hover-text-color: $white-base;


$default-box-shadow-h-offset: 0 !default;
$default-box-shadow-v-offset: 0 !default;
$default-box-shadow-blur: 4px !default;
$default-box-shadow-spread: null !default;
$default-box-shadow-color: rgba(0, 0, 0, .15) !default;
$default-box-shadow-inset: null !default;

/** Box shadows */


.dd-menu {
  display: inline-block;
  position: fixed;
  z-index: 9;
  top: 10px;
  right: 20px;
  text-align: center;

  &.dd-menu-left {
     text-align: left;


     .dd-menu-items {
       right: -20px;
	  border-top: none;
       button, a {
         text-align: left;
       }
     }
   }


   .dd-menu-items {
       position: absolute;
       z-index: $dd-menu-z-index;
       margin-top: 0.5em;


       > ul,
       > ol {
	  width: 250px;
	  border: 1px solid #ccc;
         list-style: none;
         padding: 0;
         margin: 0;
         background-color: $dd-list-color;
         color: $dd-text-color;
         @include menu-box-shadow();

	  > li {
		  height: 40px;
		  padding-left: 5px;
		  cursor: pointer;
		  font-size: 15px;
	  }

         > li {
           &:hover,
           > button:focus,
           > a:focus {
             color: $dd-hover-text-color;
             background-color: #d9e99d;
             outline: none;
	      height: 40px;
           }



           > button {
             @extend .clear-btn;
           }

         }
       }
     }



	 li.separator {
	     content: '';
	     height: 1px;
	     background-color: rgba(0, 0, 0, .15);
	     margin-top: .5em;
	     margin-bottom: .5em;
	   }

}





.clear-btn {
  border: none;
  background-color: transparent;
}


/* ========================================================= */
/* Dropdown menu Animations */
/* ========================================================= */
.grow-from-left-enter {
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform $default-transition-time $default-cubic-in;

  &.grow-from-left-enter-active {
    transform: scale(1);
  }
}

.grow-from-left-leave {
  transform: scale(1);
  transform-origin: 0 0;
  transition: transform $default-transition-time ease-out;

  &.grow-from-left-leave-active {
    transform: scale(0);
  }
}

.grow-from-right-enter {
  transform: scale(0);
  transform-origin: 100% 0;
  transition: transform $default-transition-time $default-cubic-in;

  &.grow-from-right-enter-active {
    transform: scale(1);
  }
}

.grow-from-right-leave {
  transform: scale(1);
  transform-origin: 100% 0;
  transition: transform $default-transition-time ease-out;

  &.grow-from-right-leave-active {
    transform: scale(0);
  }
}

.grow-from-center-enter {
  transform: scale(0);
  transform-origin: 50% 0;

  &.grow-from-center-enter-active {

  transition: transform $default-transition-time $default-cubic-in;
    transform: scale(1);
  }
}

.grow-from-center-leave {
  transform: scale(1);
  transform-origin: 50% 0;

  &.grow-from-center-leave-active {
  transition: transform $default-transition-time $default-cubic-in;
    transform: scale(0);
  }
}






.about_div_bgimg_wrapper {
	width: 100%;
	overflow: hidden;
	  border-bottom: 1px solid #eee
}

.about_div_bgimg {
	    height: 95vh;
	    background-size: contain;
	    background-repeat: no-repeat;
	    background-position: top center;

}

.about_div_bgimg_cover {
	    height: 100vh;
	    background-size: cover;
	    background-repeat: no-repeat;
	    background-position: top center;

}




progress
{

	/* Turns off styling - not usually needed, but good to know. */
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;

	/* gets rid of default border in Firefox and Opera. */
	border: none;

	/* Dimensions */
	width: 500px;
	height: 12px;

}

.rm-progress {
	background: #cccccc;
	border-radius: 5px;
}

/* Firefox */
progress.rm-progress::-moz-progress-bar {
	border-radius: 5px;
	background-image: -moz-linear-gradient(
		center bottom,
		rgb(220,60,60) 37%,
		rgb(239,117,116) 69%
	);
}

/* Chrome */
progress.example3::-webkit-progress-value {
	border-radius: 5px;
	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0, rgb(220,60,60)),
		color-stop(1, rgb(84,240,84))
	);
	background-image: -webkit-linear-gradient(
		center bottom,
		rgb(220,60,60) 37%,
		rgb(239,117,116) 69%
	);
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}



.react-slideshow-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

}

.react-slideshow-container .nav {
  z-index: 10;
  position: absolute;
  cursor: pointer;
}

.react-slideshow-container .nav:first-of-type {
  left: 0;
}

.react-slideshow-container .nav:last-of-type {
  right: 0;
}

.react-slideshow-container .default-nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  border: none;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.react-slideshow-container .default-nav:hover,
.react-slideshow-container .default-nav:focus {
  background: #fff;
  color: #666;
  outline: none;
}

.react-slideshow-container .default-nav.disabled:hover {
  cursor: not-allowed;
}

.react-slideshow-container .default-nav:first-of-type {
  margin-right: -30px;
  border-right: none;
  border-top: none;
}

.react-slideshow-container .default-nav:last-of-type {
  margin-left: -30px;
}

.react-slideshow-container+ul.indicators {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

.react-slideshow-container+ul.indicators li {
  display: inline-block;
  position: relative;
  width: 7px;
  height: 7px;
  padding: 5px;
  margin: 0;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator {
  border: none;
  opacity: 0.25;
  cursor: pointer;
  background: transparent;
  color: transparent;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  content: '';
  background: #000;
  text-align: center;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:hover,
.react-slideshow-container+ul.indicators .each-slideshow-indicator.active {
  opacity: 0.75;
  outline: none;
}

.react-slideshow-fadezoom-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap>div {
  position: relative;
  opacity: 0;
}

.react-slideshow-wrapper .react-slideshow-fade-images-wrap>div[aria-hidden='true'] {
  display: none;
}

.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-wrapper .images-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.react-slideshow-wrapper .images-wrap>div[aria-hidden='true'] {
  display: none;
}




.slide-container {
  width: 100%;
  margin: auto;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 600px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.indicator {
  width: 30px;
  color: blue;
  text-align: center;
  cursor: pointer;
  border: 1px blue solid;
}
.indicator.active {
  background: #000;
  color: #fff;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

form {
  width: 330px;
  min-width: 330px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 20px;
}

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 4px;
  border: 1px solid rgba(50, 50, 93, 0.2);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid #777;
  height: 64px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 15px
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
